import React from 'react'

const LeftAdBox = ({ index }) => {

    return (
        <div className='adbox-left' style={{ top: (8 + (24 * index)) + 'em' }}>
            {process.env.NODE_ENV === "production" &&
                <ins className='adsbygoogle'
                    style={{ display: 'block' }}
                    data-ad-client='ca-pub-5670671762402292'
                    data-ad-slot={index + '-L'}
                    data-ad-format='auto' />
            }
        </div>
    )
};

export default LeftAdBox;