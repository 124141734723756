import React, { useState } from 'react';
import * as Utils from '../helpers/Utilities';

const PostMessage = ({ data, index }) => {
    const [hovered, setHovered] = useState(false);
    return (
        <div
            id={`msg-${data.id}`}
            className="cursor-pin something-msg"
            onPointerEnter={() => setHovered(true)}
            onPointerLeave={() => setHovered(false)}
            onTouchStart={() => setHovered(true)}
            onTouchCancel={() => setHovered(false)}
            onTouchEnd={() => setHovered(false)}
            style={{
                top: `${data.locData.Y}em`,
                left: `${data.locData.X}em`,
                zIndex: hovered ? 9999999 : index,
                transform: `rotate(${data.locData.Rotation}deg)`,
                filter: `hue-rotate(${data.locData.RandomPaperColorTransform}deg)`,
                boxShadow: hovered ? '20px 20px 8px #000' : ''
            }}>
            <div id={`pin-${data.id}`}
                style={{
                    top: `-2.5em`,
                    left: `9em`,
                    position: 'relative',
                    zIndex: index + 1
                }}>
                <img
                    src="/img/pin.png"
                    alt="pin"
                    style={{
                        width: "2em",
                        height: "3.75em",
                        filter: `filter:hue-rotate(${data.locData.PinHueRotation}deg)`,
                        transform: hovered ? 'rotate(0deg)' : `rotate(${data.locData.PinRotation}deg)`
                    }}
                />
            </div>
            <div className="something-msg-contents">
                {data.Content}
                {hovered && <div style={{
                    position: 'absolute',
                    top: '17em',
                    width: '100%'
                }}>Posted: {Utils.formatDate(data.date)}</div>}
            </div >
        </div >
    )
};

export default PostMessage;