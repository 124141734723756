import React from 'react'

const TopAdBox = ({ index }) => {
    return (
        <div className='adbox-top' style={{ left: (25 + (65 * index)) + 'em' }}>
            {process.env.NODE_ENV === "production" &&
                <ins className='adsbygoogle'
                    style={{ display: 'block' }}
                    data-ad-client='ca-pub-5670671762402292'
                    data-ad-slot={index + '-T'}
                    data-ad-format='auto' />
            }
        </div>
    )
};

export default TopAdBox;