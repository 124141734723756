import React from "react";
import LeftAdBox from "./LeftAdBox";
import _ from "lodash";

//TODO: Phase 1: Create ad service and get ads

const LeftAdBoxGroup = ({countOfAds}) => { 
    return(
        <div id="adbox-left-group">
            {_.times(countOfAds, i => <LeftAdBox key={i} index={i} />)}
        </div>
    )
};
export default LeftAdBoxGroup