import React, { useEffect, useState } from "react";

const TagsInput = ({ handleChange }) => {
    const [input, setInput] = useState('');
    const [tags, setTags] = useState([]);
    const [isKeyReleased, setIsKeyReleased] = useState(false);

    useEffect(() => { 
        handleChange(tags);
    }, [handleChange, tags]);

    const onChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    const onKeyDown = (e) => {
        const { key } = e;
        const trimmedInput = input.trim();

        if ((key === ',' || key === 'Enter') && trimmedInput.length && !tags.includes(trimmedInput)) {
            e.preventDefault();
            setTags(prevState => [...prevState, trimmedInput]);
            setInput('');
        }
        if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
            e.preventDefault();
            const tagsCopy = [...tags];
            const poppedTag = tagsCopy.pop();

            setTags(tagsCopy);
            setInput(poppedTag);
        }

        setIsKeyReleased(false);
    };

    const onKeyUp = () => {
        setIsKeyReleased(true);
    }

    const deleteTag = (tagIndex) => {
        setTags(prevState => prevState.filter((tag, i) => i !== tagIndex));
    }

    return (
        <div className="tags-container">
            {tags.map((tag, index) => (
                <div key={index} className="tag">
                    {tag}
                    <button type="button" onClick={() => { deleteTag(index) }}>X</button>
                </div>

            ))}
            <input
                value={input}
                placeholder="(Optional) Enter a tag"
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onChange={onChange}
            />
        </div>
    )
};

export default TagsInput;