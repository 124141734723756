import React from 'react';
import io from "socket.io-client";
const protocol = (process.env.NODE_ENV === "development" ? 'http://' : 'https://');
const host = (process.env.REACT_APP_API_HOST);
const divider = (process.env.NODE_ENV === "development" ? ':' : '/');
const port = (process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_PORT : '');
export const socket = io(`${protocol}${host}${divider}${port}`);
export const SocketContext = React.createContext(socket);

socket.on('pleasetryagain', (cmd)=>{
    socket.emit(cmd.command, cmd.data);
});