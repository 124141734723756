import assert from 'assert';
export class LocData {
    constructor(PinX, PinY, PinHueRotation,
        PinRotation, RandomPaperColorTransform,
        Rotation, X, Y) {
        this.PinX = PinX;
        this.PinY = PinY;
        this.PinHueRotation = PinHueRotation;
        this.PinRotation = PinRotation;
        this.RandomPaperColorTransform = RandomPaperColorTransform;
        this.Rotation = Rotation;
        this.X = X;
        this.Y = Y;
    }

    valid() {
        const corruptMessage = "Corrupt loc data detected! ";
        assert(typeof this.PinX === "number", corruptMessage + "PinX");
        assert(typeof this.PinY === "number", corruptMessage + "PinY");
        assert(typeof this.PinHueRotation === "number", corruptMessage + "PinHueRotation");
        assert(typeof this.RandomPaperColorTransform === "number", corruptMessage + "RandomPaperColorTransform");
        assert(typeof this.Rotation === "number", corruptMessage + "Rotation");
        assert(typeof this.X === "number", corruptMessage + "X");
        assert(typeof this.Y === "number", corruptMessage + "Y");
        return true;
    }
}

export class PinData {
    constructor(locData, Content, Tags, Filters, Email = undefined) {
        if (locData instanceof LocData && locData.valid()) {
            this.locData = locData;
            this.Content = Content;
            this.date = new Date();
            if (Tags !== undefined && Array.isArray(Tags)) {
                this.Tags = [];
                Tags = Tags.slice(0, 9); //Limit to ten tags.
                Tags.forEach((tag) => {
                    //Tags can only be alpha and length must be less than 20
                    if (tag.match(/[a-z\s]/gi) && tag.length <= 20) {
                        this.Tags.push(tag);
                    }
                });
            }
            this.Tags = Tags;
            this.Filters = Filters;
            if (Email !== undefined && ValidateEmail(Email)) {
                this.Email = Email;
            }
        }
        console.log(JSON.stringify(this, '', 2));
    }
}

function ValidateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    return (false)
}