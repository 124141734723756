import React from "react";
import TopAdBox from "./TopAdBox";
import _ from "lodash";

const TopAdBoxGroup = ({countOfAds}) => {
    return (
        <div id="adbox-top-group">
            {_.times(countOfAds, i => <TopAdBox key={i} index={i} />)}
        </div>
    )
};
export default TopAdBoxGroup