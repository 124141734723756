import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import axios from "axios";
import { SocketContext } from "../context/socket";

const APIKEY = process.env.REACT_APP_COUNTRIES_API;
const APIURL = process.env.REACT_APP_COUNTRIES_URL;

const BoardSelectors = ({ onChange }) => {
    const socket = useContext(SocketContext);
    const [countryPickerDisabled, setCountryPickerDisabled] = useState(true);
    const [statePickerDisabled, setStatePickerDisabled] = useState(true);
    const [cityPickerDisabled, setCityPickerDisabled] = useState(true);

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    
    const countryPicker = useRef(null);
    const statePicker = useRef(null);
    const cityPicker = useRef(null);

    useEffect(() => {
        axios.get(APIURL + '/countries', {
            headers: {
                "X-CSCAPI-KEY": APIKEY
            }
        }).then((results) => {
            setCountryList(results?.data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const handlePostsCount = useCallback((postCountData) => {
        const { Country, StateOrProvince, count } = postCountData;
        if (count > 100) setCountryPickerDisabled(false);
        if (Country !== '' && count > 100) setStatePickerDisabled(false);
        if (Country !== '' && StateOrProvince !== '' && count > 100) setCityPickerDisabled(false);
    }, [setCountryPickerDisabled, setStatePickerDisabled, setCityPickerDisabled]);

    useEffect(() => {
        socket.off('postsCount');
        socket.on('postsCount', handlePostsCount);
        setTimeout(() => {
            socket.emit('getPostsCount', { Country: '', StateOrProvince: '', City: '' });
        }, 500);
    }, [socket, handlePostsCount]);

    const handleChange = (picker) => {
        switch (picker) {
            case "COUNTRY":
                setCityPickerDisabled(true);
                if (countryPicker.current.value !== '') {
                    axios.get(APIURL + `/countries/${countryPicker.current.value}/states`, {
                        headers: {
                            "X-CSCAPI-KEY": APIKEY
                        }
                    }).then((results) => {
                        setStateList(results?.data);
                        setStatePickerDisabled(false);
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    setStatePickerDisabled(true);
                }
                break;
            case "STATE":
                if (statePicker.current.value !== '') {
                    axios.get(APIURL + `/countries/${countryPicker.current.value}/states/${statePicker.current.value}/cities`, {
                        headers: {
                            "X-CSCAPI-KEY": APIKEY
                        }
                    }).then((results) => {
                        setCityList(results?.data);
                        setCityPickerDisabled(false);
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    setCityPickerDisabled(true);
                }
                break;
            default:
                break;
        }
        socket.emit('getPostsCount', {
            Country: countryPicker?.current?.value || '',
            StateOrProvince: statePicker?.current?.value || '',
            City: cityPicker?.current?.value || ''
        })
        onChange({
            Country: countryPicker?.current?.value || '',
            StateOrProvince: statePicker?.current?.value || '',
            City: cityPicker?.current?.value || ''
        })
    };

    return (
        <form>
            <div className="country-state-city-pickers m03">
                {!countryPickerDisabled && <select id="CountryList" ref={countryPicker} onChange={() => { handleChange('COUNTRY') }} disabled={countryPickerDisabled}>
                    <option></option>
                    {countryList &&
                        countryList.map((country, index) => (
                            <option value={country.iso2} key={index}>{country.name}</option>
                        ))
                    }
                </select>}
            </div>
            <div className="country-state-city-pickers m03">
                {!statePickerDisabled && <select id="StateOrProvinceList" ref={statePicker} onChange={() => { handleChange('STATE') }} disabled={statePickerDisabled}>
                    <option></option>
                    {stateList &&
                        stateList.map((state, index) => (
                            <option value={state.iso2} key={index}>{state.name}</option>
                        ))
                    }
                </select>}
            </div>
            <div className="country-state-city-pickers m03">
                {!cityPickerDisabled && <select id="CityList" ref={cityPicker} onChange={() => { handleChange('CITY') }} disabled={cityPickerDisabled}>
                    <option></option>
                    {cityList &&
                        cityList.map((city, index) => (
                            <option value={city.name} key={index}>{city.name}</option>
                        ))
                    }
                </select>}
            </div>
        </form>
    )
}

export default BoardSelectors;