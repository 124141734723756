import React from 'react'
import NavBar from '../components/NavBar';

//TODO: Phase 2: Build this out to display frequently mentioned words in Content.

function PopularTopics() {
    return (
        <div>
            <NavBar />
            <div className="info-board">
                <div id="notice-pin" className='text-center'>
                    Under construction...
                </div>
            </div>
        </div>
    )
}

export default PopularTopics;