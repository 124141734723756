import './css/App.css';
import React, { useState } from 'react';
import { socket, SocketContext } from './context/socket';
import NavBar from './components/NavBar';
import BoardSelectors from './components/BoardSelectors';
import TopAdBoxGroup from './components/ads/TopAdBoxGroup';
import LeftAdBoxGroup from './components/ads/LeftAdBoxGroup';
import PostBoard from './components/PostBoard';

const App = () => {

  const [filters, setFilters] = useState({
    Country: '',
    StateOrProvince: '',
    City: ''
  });

  const handleBoardSelectorChange = (selection) => {
    console.log(selection);
    socket.emit('filters-changed', selection);
    setFilters(selection);
  };

  return (
    <div>
      <SocketContext.Provider value={socket}>
        <NavBar />
        <BoardSelectors onChange={handleBoardSelectorChange} />
        <LeftAdBoxGroup countOfAds={8} />
        <TopAdBoxGroup countOfAds={5} />

        <div id="modal-holder" className="empty"></div>
        <div id="anim-holder" className="cursor-hidden empty"></div>

        <PostBoard filters={filters}/>
      </SocketContext.Provider>
    </div>
  );
}

export default App;
