import React, { useEffect, useState } from 'react'
import { PinData, LocData } from '../helpers/PinData';
import * as Utils from '../helpers/Utilities';
import TagsInput from './TagsInput';


const PinForm = ({ clickData, filters, submit, cancel }) => {
    const [locData, setLocData] = useState(null);
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [tags, setTags] = useState([]);

    useEffect(() => {
        const pinXoffset = Number(((Math.random() * 16) + 4).toFixed(2)) - 4;
        const pinYoffset = Number((Math.random() * - 1).toFixed(2));
        const rotation = Utils.rotation();
        const pinRotation = Utils.pinRotation();

        //Protect against the edges for pinning
        let x = clickData.pageX - 10;
        let y = clickData.pageY;
        if (x < 2) x += 12;
        if (x > 375) x -= 12;
        if (y < 3) y += 3;
        if (y > 275) y -= 20;

        const lclLocData = new LocData(
            pinXoffset, pinYoffset, Utils.GetColorSkew(),
            pinRotation, Utils.GetColorSkew(), rotation,
            x, y);

        console.log(lclLocData);
        if (lclLocData.valid()) {
            setLocData(lclLocData);
        }
    }, [clickData]);

    const handleSubmit = () => {
        //Create pin data and submit.
        console.log(filters);
        const pinData = new PinData(locData, message, tags, filters, email);
        console.log(pinData);
        submit(pinData);
    }

    return (
        <>
            {locData &&
                <div
                    id={`msg-new`}
                    className="cursor-pin something-msg"
                    style={{
                        top: `${locData.Y}em`,
                        left: `${locData.X}em`,
                        zIndex: 10000009,
                        transform: `rotate(${locData.Rotation}deg)`,
                        filter: `hue-rotate(${locData.RandomPaperColorTransform}deg)`,
                    }}
                >
                    <div
                        id="pin-anim"
                        style={{
                            top: `${locData.PinY}em`,
                            left: `${locData.PinX}em`,
                            position: 'relative',
                            transform: `rotate(${locData.PinRotation}deg)`,
                            zIndex: 10000010
                        }}>
                        <img
                            id="pin-anim-img"
                            src="/img/pin.png"
                            alt="pin"
                            style={{
                                width: "2em",
                                height: "3.75em",
                                filter: `hue-rotate(${locData.pinColor}deg)`
                            }}
                        />
                    </div>
                    <form style={{
                        marginLeft: '1em',
                        marginRight: '1em',
                        marginBottom: '0.1em'
                    }}>
                        <label>Message:</label>
                        <textarea id="message-contents"
                            onChange={(e) => { setMessage(e.target.value) }}
                            maxLength={175}
                            minLength={1}
                            style={{
                                width: "100%",
                                height: "6em",
                                resize: 'none',
                            }}></textarea>
                        <TagsInput handleChange={(tags)=>{setTags(tags)}} />
                        <label>Email:</label>
                        <input id="message-email"
                            onChange={(e) => { setEmail(e.target.value) }}
                            type="email"
                            style={{
                                marginBottom: '0.1em',
                                width: '85%'
                            }} disabled={true} />
                        <div style={{ display: 'flex' }}>
                            <button
                                type='button'
                                className='button-6 center'
                                style={{
                                    margin: 'auto auto'
                                }}
                                onClick={() => handleSubmit()}>Submit</button>
                            <button
                                type='button'
                                className='button-6 center'
                                style={{
                                    margin: 'auto auto'
                                }}
                                onClick={() => cancel()}>Cancel</button>
                        </div>
                    </form>
                </div>
            }
        </>
    )
};

export default PinForm;