import React from 'react'
import NavBar from '../components/NavBar';

//TODO: Phase 2: Build this page out to make it possible to search by tag

function SearchTags(){
    return(
        <div>
            <NavBar/>
            <h1 className='place-content-center'>Search Tags</h1>
            <div id="notice-pin" className='text-center'>
                Under construction...
            </div>
        </div>
    )
}

export default SearchTags;