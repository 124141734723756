import React, { useCallback, useContext, useEffect, useState } from "react";
import { SocketContext } from "../context/socket";
import PinForm from "./PinForm";
import PostMessage from "./PostMessage";
import * as Utils from "../helpers/Utilities";

const PostBoard = ({filters}) => {
    const [posts, setPosts] = useState([]);
    const [pinned, setPinned] = useState(false);
    const [clickData, setClickData] = useState({});
    const socket = useContext(SocketContext);

    const handleNewPosts = useCallback((newPosts) => {
        setPosts([
            ...posts,
            ...newPosts
        ]);
    }, [posts]);

    const handleDeletePosts = useCallback((oldPosts)=>{
        console.log('Delete Posts received:', JSON.stringify(oldPosts, '', 2));
        setPosts([
            ...posts.filter(post => !oldPosts.includes(post.id))
        ])
    }, [posts])

    useEffect(()=>{
        setPosts([]);
    },[filters]);

    useEffect(() => {
        // here we can use socket events and listeners\

        socket.off('newPostsBatch');
        socket.on('newPostsBatch', handleNewPosts);

        socket.off('deletePostsBatch');
        socket.on('deletePostsBatch', handleDeletePosts);

    }, [socket, handleNewPosts, handleDeletePosts]);

    // Features: Draw Messages
    // A pin animation for onClick, no pin animation for long touch input.
    // A modal dialog for creating a post (separate component)
    // post submition
    // hover should show msg above others
    // touch input should show msg above others

    const handleClick = (e) => {
        if (!pinned) {
            setClickData({
                pageX: Utils.PixToEm(e.pageX) - Utils.PixToEm(403),
                pageY: Utils.PixToEm(e.pageY) - Utils.PixToEm(130),
            })
            setPinned(true);
        }
    };

    const handleSubmit = (pinData) => {
        socket.emit('submitPost', pinData);
        setPinned(false);
    }

    const handleCancel = () => {
        setPinned(false);
    }

    return (
        <div id="postboard-wrapper">
            <div
                id="postboard"
                className="cursor-pin"
                onClick={(e) => handleClick(e)}>
                    <div style={{
                        position: 'absolute',
                    }}>{posts.length}</div>
                {
                    posts.map((post, index) => <PostMessage key={index} data={post} index={index} />)
                }
                {
                    pinned &&
                    <PinForm
                        clickData={clickData}
                        filters={filters}
                        submit={handleSubmit}
                        cancel={handleCancel}
                    />
                }
            </div>
        </div>
    );
};

export default PostBoard;