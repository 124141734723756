export const GetColorSkew = () => {
    return Math.floor((Math.random() * 360) + 1);
}

export const PixToEm = (pixels) => {
    return pixels / 16;
};

export const pinRotation = () => {
    let pinRotation = (Math.random() * 20);
    if (Math.random() > 0.5) {
        pinRotation = -pinRotation;
    }
    return pinRotation;
};

export const rotation = () => {
    var rotation = Math.random() * 25;
    if (Math.random() > 0.5) {
        rotation = -rotation;
    }
    return rotation;
}

export const formatDate = (datetime) => {
    const date = new Date(datetime);
    return date.toDateString() + " " + date.toTimeString();
}