import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
    return (
        <nav className="flex space-x-4">
            {[
                ['PostSomething!', '/'],
                ['Popular Topics', '/populartopics'],
                ['Search Tags', '/searchtags'],
                ['About', '/about'],
            ].map(([title, url]) => (
                <Link to={url} className="rounded-sm py-2 font-medium hover:bg-slate-100 hover:text-slate-900" key={title}>{title}</Link>
            ))}
        </nav>
    );
}

export default NavBar;